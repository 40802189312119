:root {
  --version: 1.39;
}

header.site-header {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(6, 52, 98, 0.8)), to(#063462));
  background: linear-gradient(360deg, rgba(6, 52, 98, 0.8), #063462);
  position: relative;
  z-index: 1001;
}
header.site-header .container .wrapper {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-pack: start;
          justify-content: flex-start;
  -webkit-box-align: stretch;
          align-items: stretch;
  align-content: stretch;
  width: 100%;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
@media (max-width: 991.98px) {
  header.site-header .container .logo-container {
    -webkit-box-ordinal-group: 2;
            order: 1;
    width: 50%;
    -webkit-box-flex: 1;
            flex-grow: 1;
    display: -webkit-box;
    display: flex;
  }
}
header.site-header .container .logo-container .logo {
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  padding-top: 2px;
  padding-bottom: 5px;
}
@media (max-width: 991.98px) {
  header.site-header .container .logo-container .logo {
    padding-top: 0.5625rem !important;
    padding-bottom: 0.5625rem !important;
  }
}
header.site-header .container .logo-container .logo img,
header.site-header .container .logo-container .logo svg {
  height: 2.0625rem;
  width: 8.75rem;
  margin-right: 3.125rem !important;
}
@media (min-width: 992px) {
  header.site-header .container .logo-container .logo img,
  header.site-header .container .logo-container .logo svg {
    height: 2.75rem !important;
    width: 11.4375rem;
    margin-right: 3.125rem !important;
  }
}
header.site-header .search-container {
  height: 2.84375rem;
}
@media (max-width: 767.98px) {
  header.site-header .search-container [data-event=SearchBarLoaded]:empty {
    content: "";
    background: white;
    width: 100%;
    min-height: 2.84375rem;
    border-radius: 0.1875rem;
  }
}
header.site-header .search-container:not(:empty) {
  -webkit-box-flex: 4;
          flex-grow: 4;
}
@media (max-width: 991.98px) {
  header.site-header .search-container:not(:empty) {
    width: 50%;
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
}
header.site-header .search-container:not(:empty) .search-bar {
  width: 100%;
  max-width: 100%;
  -webkit-transition: width 0.35s cubic-bezier(0.6, -0.28, 0.74, 0.05), max-width 0.35s cubic-bezier(0.6, -0.28, 0.74, 0.05);
  transition: width 0.35s cubic-bezier(0.6, -0.28, 0.74, 0.05), max-width 0.35s cubic-bezier(0.6, -0.28, 0.74, 0.05);
}
@media (max-width: 991.98px) {
  header.site-header .search-container:not(:empty) {
    margin-top: 0.625rem;
    -webkit-transition: width 250ms ease;
    transition: width 250ms ease;
  }
}
@media (min-width: 992px) {
  header.site-header .search-container:not(:empty) .search-bar:not(:focus-within) {
    max-width: 18.75rem;
  }
}
@media (min-width: 1280px) {
  header.site-header .search-container:not(:empty) .search-bar:not(:focus-within) {
    max-width: 25rem;
  }
}
header.site-header .actions-container {
  -webkit-box-flex: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
          align-items: center;
}
@media (max-width: 991.98px) {
  header.site-header .actions-container {
    -webkit-box-ordinal-group: 3;
            order: 2;
    width: 50%;
  }
}
header.site-header nav {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center;
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}
@media (min-width: 992px) {
  header.site-header nav {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
header.site-header nav .nav-left {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
}
header.site-header nav .nav-right {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
          align-items: center;
  color: #ffffff;
  width: 100%;
}
@media (max-width: 991.98px) {
  header.site-header nav .nav-right {
    width: auto;
  }
}
@media (min-width: 992px) {
  header.site-header .toggle-search-menu {
    display: none;
  }
}
header.site-header input.form-control.search-input {
  z-index: 10;
  border-left: 1px solid #e5e7ed !important;
}
@media (min-width: 992px) {
  header.site-header input.form-control.search-input {
    border-left: 0 !important;
    border-top-left-radius: 0.1875rem !important;
    border-bottom-left-radius: 0.1875rem !important;
  }
}
header.site-header input.form-control.search-input::-webkit-input-placeholder {
  color: #a7afc3;
  -webkit-transition: color 250ms ease;
  transition: color 250ms ease;
}
header.site-header input.form-control.search-input::-moz-placeholder {
  color: #a7afc3;
  -moz-transition: color 250ms ease;
  transition: color 250ms ease;
}
header.site-header input.form-control.search-input::placeholder {
  color: #a7afc3;
  -webkit-transition: color 250ms ease;
  transition: color 250ms ease;
}
header.site-header input.form-control.search-input:focus::-webkit-input-placeholder {
  color: #a7afc3;
}
header.site-header input.form-control.search-input:focus::-moz-placeholder {
  color: #a7afc3;
}
header.site-header input.form-control.search-input:focus::placeholder {
  color: #a7afc3;
}
header.site-header .autocomplete-suggestions {
  z-index: 1059;
}
@media (min-width: 992px) {
  header.site-header .autocomplete-suggestions {
    width: -webkit-calc(100% + 1px) !important;
    width: calc(100% + 1px) !important;
    left: -1px !important;
  }
}
header.site-header .autocomplete-suggestions:not(:empty) {
  border-radius: 0 0 0.1875rem 0.1875rem !important;
  box-shadow: 0.25rem 0.5rem 0.5rem rgba(6, 52, 98, 0.1);
  border: 0;
  border-top: 1px solid #e5e7ed;
  overflow: hidden;
}
header.site-header .autocomplete-suggestions:not(:empty) + .input-group * {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.offcanvas-shadow {
  box-shadow: 0.25rem 0.5rem 0.5rem rgba(6, 52, 98, 0.1) !important;
  border-right: 0 !important;
}

.offcanvas-title {
  font-size: 1.25rem !important;
  font-weight: 700;
}

@media (max-width: 575.98px) {
  .offcanvas-start {
    width: 100%;
    max-width: 25rem;
  }
}
@media (min-width: 576px) {
  .offcanvas-start {
    max-width: 21.6875rem !important;
  }
}

[data-bs-toggle=dropdown]:not(.show) .collapse-icon {
  display: none;
}
[data-bs-toggle=dropdown]:not(.show) .expand-icon {
  display: initial;
}
[data-bs-toggle=dropdown].show .expand-icon {
  display: none;
}
[data-bs-toggle=dropdown].show .collapse-icon {
  display: initial;
}

.message-counter {
  background-color: #ff3944;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  font-size: 0.875rem;
  position: absolute;
  right: 0.5rem;
  top: -0.8rem;
  border-radius: 50%;
  color: #ffffff;
  font-weight: bold;
}
.message-counter.big {
  width: 1.6em;
  height: 1.6em;
  line-height: 1.6em;
}
